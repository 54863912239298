<template>
  <!-- 查询详情页 -->
  <div class="queryDetails">
    <van-nav-bar :left-text="
        titleDate
          ? pageinfo.modulename + '(' + titleDate + ')'
          : pageinfo.modulename
      "
                 class="common_query_title"
                 left-arrow
                 @click-left="onClickLeft">
      <template #right>
        <van-tag v-if="branchName"
                 closeable
                 size="medium"
                 type="success"
                 @close="closeBranch">
          {{ branchName }}
        </van-tag>
        <van-icon v-if="pageinfo.can_query_branch_record"
                  name="friends-o"
                  size="18"
                  color="#ffffff"
                  style="margin-right: 6px"
                  @click="showBranch" />
        <van-icon v-if="pageinfo.appdatequery"
                  name="search"
                  size="18"
                  color="#ffffff"
                  style="margin-left: 6px"
                  @click="showCalendar = true" />
      </template>
    </van-nav-bar>
    <div class="overflow_div"
         :style="overflowStyle">
      <!-- 查询条件--日期 -->
      <div class="date_content"
           v-if="showYears || showMonths || showDates">
        <div v-if="showYears"
             class="year_box">
          <div class="icon_box"
               @click="arrowsYear('left')">
            <van-icon name="arrow-left" />
          </div>
          <div class="year_btn"
               v-for="(it, idx) in years"
               :key="idx"
               :class="[
              1 == 1 ? 'cao' + idx : '',
              checkedYear == it ? 'checkd_year' : ''
            ]"
               @click="getOneYear(it)">
            <div class="year">{{ it + '年' }}</div>
          </div>
          <div class="icon_box"
               @click="arrowsYear('right')">
            <van-icon name="arrow" />
          </div>
        </div>
        <div v-if="showMonths"
             class="month_box">
          <div class="month_btn"
               v-for="(it, idx) in months"
               :key="idx">
            <div class="month"
                 :class="checkedMonth == it ? 'checkd_Month' : ''"
                 @click="getOneMonth(it)">
              {{ it + '月' }}
            </div>
          </div>
        </div>
        <div v-if="showDates"
             class="date_box">
          <div class="head_date">
            <div class="icon_box"
                 @click="arrowDates('left')">
              <van-icon name="arrow-left" />
            </div>
            <div class="date_box"
                 v-for="(it, idx) in weeks"
                 :key="idx"
                 @click="getOneDay(it)">
              <div class="week">{{ it.week }}</div>
              <div class="date">
                <div class="text"
                     :class="checkdate == it.date ? 'bgtext' : ''">
                  {{ it.date }}
                </div>
              </div>
            </div>
            <div class="icon_box"
                 @click="arrowDates('right')">
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
      </div>
      <!-- 表格内容区 -->
      <div v-if="showTable"
           class="table_content"
           :style="tabStyle">
        <div class="auto_table">
          <table ref="table"
                 class="table">
            <!-- 表头 -->
            <tr class="top">
              <th v-for="(item_th, index_th) in thList.filter(it => {
                  return it.gridwith
                })"
                  :key="index_th">
                <div class="title"
                     :style="{ width: item_th.gridwith * 1.5 + 'px' }">
                  {{ item_th.chnname }}
                </div>
              </th>
            </tr>
            <!--展示列表数据 -->
            <tr class="cont"
                v-for="(item_tr, index_tr) in tableData"
                :key="index_tr"
                :style="item_tr.v8_rgb_color_field?`background:${item_tr.v8_rgb_color_field}`:''">
              <td v-for="(item_td, index_td) in thList.filter(it => {
                  return it.gridwith
                })"
                  :key="index_td">
                <div class="fujian_box"
                     v-if="item_td.fieldname == 'atfileflag'">
                  <van-icon v-if="showAtfile && item_tr.atfileflag == 1"
                            :name="require('@assets/img/ft_ico_fj.png')" />
                </div>
                <div v-else
                     class="comm_box">
                  <div class="boolean_box"
                       v-if="item_td.fielddatatype == 'bit'">
                    <van-checkbox v-model="item_tr[item_td.fieldname]"
                                  disabled
                                  shape="square"></van-checkbox>
                  </div>
                  <div v-else>{{ item_tr[item_td.fieldname] }}</div>
                </div>
              </td>
            </tr>
            <!-- 最后一行的合计数据 需要高亮 -->
            <tr v-if="pageinfo.openfooter"
                class="cont sum">
              <td v-for="(item, index) in totalData"
                  :key="index">
                {{ item }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- 表单内容区 -->
      <div v-if="showForm"
           class="form_content"
           :style="formStyle">
        <div class="userInfoRight"
             v-if="formValue.is_wxsign">
          <div class="svg_sigin">
            <svg-icon icon-class="zu820"></svg-icon>
          </div>
        </div>
        <!-- 不分组表单 -->
        <div class="noGroup_box"
             v-if="noGroupForm.length > 0">
          <div class="noGroup_form">
            <div class="peField"
                 v-for="(it, idx) in noGroupForm"
                 :key="idx">
              <!-- 不可编辑的输入框（除开关类型和文本域） -->
              <van-field v-if="it.fielddatatype != 'bit'"
                         :label="it.chnname"
                         v-model="formValue[it.fieldname]"
                         readonly />
              <!-- 不可编辑的开关 -->
              <van-field v-else
                         name="switch"
                         class="not_canedi"
                         :label="it.chnname">
                <template #input>
                  <van-switch v-model="formValue[it.fieldname]"
                              disabled
                              size="20" />
                </template>
              </van-field>
            </div>
          </div>
        </div>
        <!-- 分组表单 -->
        <van-collapse v-model="activeNames"
                      :style="collapseStyle">
          <van-collapse-item v-for="(item, index) in groupForm"
                             :key="index"
                             :name="index">
            <template #title>
              <div class="title_bg">
                <img :src="require('@assets/img/form_title.png')"
                     alt="" />
              </div>
              <div class="xiba_title">
                <van-icon :name="require('@assets/img/ft_ico_' + (index + 1) + '.png')" />
                <div>{{ item.name }}</div>
              </div>
            </template>
            <div class="peField"
                 v-for="(it, idx) in item.origin"
                 :key="idx">
              <!-- 不可编辑的输入框（除开关类型和文本域） -->
              <van-field v-if="it.fielddatatype != 'bit'"
                         class="not_canedi"
                         :label="it.chnname"
                         v-model="formValue[it.fieldname]"
                         readonly />
              <!-- 不可编辑的开关 -->
              <van-field v-else
                         name="switch"
                         class="not_canedi"
                         :label="it.chnname">
                <template #input>
                  <van-switch v-model="formValue[it.fieldname]"
                              disabled
                              size="20" />
                </template>
              </van-field>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
      <!-- 日历组件 -->
      <van-calendar v-model="showCalendar"
                    type="range"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onConfirmCalendar" />
    </div>
    <!-- 底部按钮-仅限于661模块 -->
    <div v-if="
        (moduleno == 661 || moduleno == '661') &&
          use_kq_sign &&
          !this.formValue.is_wxsign
      "
         class="has_feedback">
      <div class="has_feedback_btn1"
           @click="feedback"
           v-if="use_kq_feedback">
        {{ $t('salaryDetails.sdTxt5') }}
      </div>
      <div class="has_feedback_btn2"
           @click="SignIn">
        {{ $t('salaryDetails.sdTxt2') }}
      </div>
    </div>
    <!-- // 协议弹窗 -->
    <van-popup v-model="showAgreePop"
               class="salaryAgreePop"
               round
               :close-on-click-overlay="false">
      <div class="title">
        {{ $t('salarySiginPassword.sspTxt10') }}
      </div>
      <div class="content">
        <iframe id="iframe"
                class="pay_agree_iframe"
                :src="kq_agreement_url"
                style="height:calc(100% - 20px); width: 100%;margin-top: 15px; "
                frameborder="0"></iframe>
      </div>
      <div class="footer">
        <div class="arg1"
             @click="agree">
          {{
            PrivacyTimeOut > 0
              ? `${$t('salarySiginPassword.sspTxt11')}（${PrivacyTimeOut}S）`
              : $t('salarySiginPassword.sspTxt11')
          }}
        </div>
        <div class="arg2"
             @click="notAgree">
          {{ $t('salarySiginPassword.sspTxt12') }}
        </div>
      </div>
    </van-popup>
    <!-- // 反馈内容弹窗 -->
    <van-popup v-model="showFeedback"
               closeable
               class="FeedbackPop"
               round>
      <div class="title">{{ $t('salaryDetails.sdTxt6') }}</div>
      <div class="reason">
        <van-field v-model="feedback_reason"
                   autosize
                   type="textarea"
                   :placeholder="$t('salaryDetails.sdTxt6')" />
      </div>
      <div class="footer">
        <div @click="submitReason">{{ $t('module.submit') }}</div>
      </div>
    </van-popup>
    <!-- 签名弹窗 -->
    <van-overlay :show="showSign"
                 @click="showSign = false">
      <div style="width: 100%; height: 100%"
           class="wrapper"
           @click.stop>
        <signaturePad @getSpad="getSpad"
                      @closeSpad="closeSpad"
                      :show="showSign"></signaturePad>
      </div>
    </van-overlay>

    <!-- 选择人员树弹窗 -->
    <PopTree ref="popTreeNode"
             :whichTree="'employee'"
             :module="moduleno"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
  </div>
</template>

<script>
import { Dialog, Toast, Notify } from 'vant'
import { saveAs } from 'file-saver'
import signaturePad from '@components/signaturePad'
import PopTree from '@components/PopTree/index.vue'
import {
  getHrQueryPageInfo,
  getHrQueryRecord,
  getKqSignParam,
  agreeKqSignProtocol,
  getLastKqSignFeedBackInfo,
  saveKqSignFeedBack,
  signMonthKqSgl,
  ybePostFileStr,
  signMonthKqSglRawJson,
  openKqMonthReport
} from '@api/wx.js'
export default {
  components: {
    signaturePad,
    PopTree
  },
  data () {
    const fromH5 = this.$route.query.fromH5
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    const moduleno = this.$route.query.moduleno
    const param = this.$route.query.param
    return {
      userInfo,
      fromH5, // 是否从H5过来的
      moduleno, // 当前模块号
      empcode: null, // 当前登录的工号
      langid: 0, // 当前多语言Id
      param, // 从外部过来的参数
      thList: [], // 表头列表
      tableData: [], // 表格数据
      totalData: [], // 最后一行合计数据
      overflowStyle: 'height:calc(100% - 46px)', // 包含条件和内容区域
      tabStyle: 'height:100%', // 表格高度
      formStyle: 'height:100%', // 表单高度
      pageinfo: {}, // 页面配置信息
      fieldlist: [], // 字段信息
      showForm: false,
      showTable: false,
      groupForm: [], // 有分组的表单信息
      activeNames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      noGroupForm: [], // 没有分组的表单信息
      collapseStyle: 'height:100%', // 表单分组高度
      formValue: {}, // 表单的字段值
      valueData: [], // 记录
      showYears: false,
      years: [], // 年份数组
      checkedYear: '', // 选中的年份
      showMonths: false,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // 月份数组
      checkedMonth: '', // 选中的月份
      showDates: false,
      weeks: [],
      checkdate: '', // 选中的日期（dd）
      checkFullDate: '', // 选中的日期（yyMMdd）
      showCalendar: false, // 日历组件
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      times: 0,
      // ----------661参数-----
      isagree: false,
      kq_agreement_timeout: 5,
      nosign: false,
      use_kq_agreement: false,
      use_kq_feedback: false,
      use_kq_sign: false,
      kq_agreement_url: '',
      showAgreePop: false, // 协议弹窗
      PrivacyTimeOut: 0, // 倒计时
      PrivacyTimer: null,
      windowsignremark: window.signremark,
      showFeedback: false,
      feedback_reason: '',
      showSign: false,
      // ----------661参数-----
      branchName: '' // 下属姓名
    }
  },
  computed: {
    showAtfile () {
      let fieldnames = this.thList.map(it => {
        return it.fieldname
      })
      if (fieldnames.includes('atfileflag') && fieldnames.includes('autoid'))
      {
        return true
      } else
      {
        return false
      }
    },
    // 标题的日期
    titleDate () {
      let str = ''
      if (this.showDates && this.checkFullDate)
      {
        str =
          this.checkFullDate.split('-')[0] +
          '年' +
          this.checkFullDate.split('-')[1] +
          '月'
      }
      return str
    },
    navbarH () { // 标题高度
      const navbar = document.getElementsByClassName('common_query_title')[0]
      let hei = navbar ? navbar.clientHeight : '46px'
      return hei
    }
  },
  watch: {
    pageinfo: {
      handler (n) {
        if (n.apptemplateid != 0)
        {
          switch (n.apptemplateid)
          {
            case 1:
              this.showYears = false
              this.showMonths = false
              this.showDates = false
              this.showTable = true
              break
            case 2:
              this.showYears = false
              this.showMonths = false
              this.showDates = false
              this.showForm = true
              break
            case 3:
              this.showYears = false
              this.showMonths = false
              this.showDates = true
              this.showForm = true
              break
            case 4:
              this.showYears = true
              this.showMonths = true
              this.showDates = false
              this.showTable = true
              break
            case 5:
              this.showYears = true
              this.showMonths = true
              this.showDates = false
              this.showForm = true
              break
            case 6:
              this.showYears = true
              this.showMonths = false
              this.showDates = false
              this.showTable = true
              break
            case 7:
              this.showYears = false
              this.showMonths = false
              this.showDates = true
              this.showTable = true
              break
            case 8:
              this.showYears = true
              this.showMonths = false
              this.showDates = false
              this.showForm = true
              break
          }
          //第一次查询后判断一下，定位到当前日期
          if (
            (this.fromH5 == true || this.fromH5 == 'true') &&
            this.times == 1
          )
          {
            // console.log('H5过来的');
            this.moduleno = this.moduleno
            this.empcode = this.userInfo.empcode
            this.langid = localStorage.getItem('langid')
            // 查询页面
            if (this.showYears)
            {
              this.checkedYear = new Date().getFullYear()
            } else
            {
              this.checkedYear = ''
            }
            if (this.showMonths)
            {
              this.checkedMonth = new Date().getMonth() + 1
            } else
            {
              this.checkedMonth = ''
            }
            if (this.showDates)
            {
              this.checkdate = new Date().getDate()
              if (this.checkdate < 10)
              {
                this.checkdate = '0' + this.checkdate
              }
              this.checkFullDate = this.formatDate(new Date(), 'yyyy-MM-dd')
            } else
            {
              this.checkdate = ''
              this.checkFullDate = ''
            }
            this.param = this.checkedYear ? this.checkedYear : this.param
            this.param = this.checkedMonth
              ? this.param + '' + this.add0(this.checkedMonth)
              : this.param
            this.param = this.checkFullDate
              ? this.param +
              '' +
              this.formatDate(new Date(this.checkFullDate), 'yyyyMMdd')
              : this.param
            // this.param = this.checkedYear + '' + this.add0(this.checkedMonth) + '' + this.formatDate(new Date(this.checkFullDate), 'yyyyMMdd')
            this.getAllPageDatas(this.param)
          }
        }
      }
    },
    showTable (n) {
      if (n)
      {
        let i = 1
        let timer = setInterval(() => {
          i++
          if (i == 3)
          {
            clearInterval(timer)
          }
          const dateContent = document
            .getElementsByClassName('overflow_div')[0]
            .querySelector('.date_content')
          if (dateContent)
          {
            const dateH = dateContent.clientHeight
            this.tabStyle = 'height: calc(100% - ' + dateH + 'px)'
          }
        }, 1000)
      }
    },
    showForm (n) {
      if (n)
      {
        let i = 1
        let timer = setInterval(() => {
          i++
          if (i == 3)
          {
            clearInterval(timer)
          }
          const dateContent = document
            .getElementsByClassName('overflow_div')[0]
            .querySelector('.date_content')
          if (dateContent)
          {
            const dateH = dateContent.clientHeight
            this.formStyle = 'height: calc(100% - ' + dateH + 'px)'
          }
          const noGroupF = document
            .getElementsByClassName('overflow_div')[0]
            .querySelector('.form_content')
            .querySelector('.noGroup_form')
          if (noGroupF)
          {
            const dateF = noGroupF.clientHeight
            this.collapseStyle = 'height: calc(100% - ' + dateF + 'px)'
          }
          const has_feedback = document.getElementsByClassName(
            'has_feedback'
          )[0]
          if (has_feedback)
          {
            const feedH = has_feedback.clientHeight
            this.overflowStyle = 'height: calc(100% - ' + (feedH + this.navbarH) + 'px)'
          }
        }, 1000)
      }
    },
    formValue (n) {
      if (this.moduleno == 661 || this.moduleno == '661')
      {
        setTimeout(() => {
          const has_feedback = document.getElementsByClassName(
            'has_feedback'
          )[0]
          if (has_feedback && !n.is_wxsign)
          {
            const feedH = has_feedback.clientHeight
            this.overflowStyle = 'height: calc(100% - ' + (feedH + this.navbarH) + 'px)'
          } else
          {
            this.overflowStyle = 'height:calc(100% - ' + this.navbarH + 'px)'
          }
        }, 500)
      }
    }
  },
  created () {
    // 根据当前日期获取本周日期
    this.getMonDayAndSunDay(new Date())
    // 获取年
    this.getYearsByDate(new Date())
    /**
     * 特定模板0
     * 模板1（无需查询，用表格显示多笔记录）
     * 模板2（无需查询，用表单显示1笔记录）
     * 模板3（按日期查询1笔记录，用表单显示）
     * 模板4（按月份查询多笔记录，用表格显示）
     * 模板5（按月份查询1笔记录，用表单显示）
     * 模板6（按年份查询多笔记录，用表格显示）
     * 模板7（按日期查询多笔记录，用表格显示）
     * 模板8（按年份查询1笔记录，用表单显示）
     */
    // 如果initParams存在的话就是从H5过来的，如果不存在则是外部过来的
    if (this.fromH5 == true || this.fromH5 == 'true')
    {
      // console.log('H5过来的');
      this.moduleno = this.moduleno
      this.empcode = this.userInfo.empcode
      this.langid = localStorage.getItem('langid')
      this.getAllPageDatas('')
    } else
    {
      // console.log('外部过来的');
      this.moduleno = this.$route.query.moduleno
      this.empcode = this.$route.query.empcode
      this.langid = this.$route.query.langid
      this.param = this.$route.query.param
      // 查询页面
      this.getAllPageDatas(this.param)
      if (this.param)
      {
        this.checkedYear = this.param.substring(0, 4)
        this.checkedMonth = this.param.substring(4, 6)
      }
    }
    // 针对模块号661-考勤月报特殊处理
    if (this.moduleno == 661 || this.moduleno == '661')
    {
      this.getKqSignParamData()
    }
  },
  methods: {
    // 关闭下属人员标签
    closeBranch () {
      // 如果initParams存在的话就是从H5过来的，如果不存在则是外部过来的
      if (this.fromH5 == true || this.fromH5 == 'true')
      {
        // console.log('H5过来的');
        this.empcode = this.userInfo.empcode
      } else
      {
        // console.log('外部过来的');
        this.empcode = this.$route.query.empcode
      }
      this.branchName = ''
      // 查询页面
      this.getAllPageDatas(this.param)
    },
    // 选择下属人员弹窗回调
    treeSelectValEvent (data) {
      this.empcode = data.empcode
      this.branchName = data.empcode + '-' + data.empname
      // 查询页面
      this.getAllPageDatas(this.param)
    },
    // 查看下属数据
    showBranch () {
      this.$refs.popTreeNode.module = this.moduleno
      this.$refs.popTreeNode.treename = 'emptree'
      this.$refs.popTreeNode.editstate = 2
      this.$refs.popTreeNode.showEmpTreePop('下属')
    },
    // 签名弹窗关闭
    closeSpad () {
      this.showSign = false
    },
    // 保存反馈意见
    submitReason () {
      saveKqSignFeedBack({
        autoid: this.formValue.autoid,
        empcode: this.empcode,
        feedback_reason: this.feedback_reason
      }).then(res => {
        if (res.data[0].info)
        {
          Notify({ type: 'danger', message: res.data[0].info })
        } else
        {
          this.showFeedback = false
          Notify({ type: 'success', message: this.$t('module.submitScu') })
        }
      })
    },
    // 反馈意见
    feedback () {
      if (this.formValue.is_wxsign)
      {
        Toast.fail('当前记录已签收！')
        return
      }
      if (this.formValue.autoid)
      {
        this.showFeedback = true
        getLastKqSignFeedBackInfo({
          empid: this.userInfo.empid,
          yymm: this.param
        }).then(res => {
          this.feedback_reason = res.data[0].info
        })
      } else
      {
        Toast.fail('当前未查询到记录！')
      }
    },
    // 签收按钮
    SignIn () {
      if (this.formValue.is_wxsign)
      {
        Toast.fail('当前记录已签收！')
        return
      }
      if (this.formValue.autoid)
      {
        if (this.nosign == true || this.nosign == 'true')
        {
          // 证明不需要签名
          Dialog.confirm({
            title: this.$t('salaryDetails.sdTxt2'),
            message: this.windowsignremark,
            confirmButtonColor: '#2B8DF0'
          })
            .then(() => {
              // on confirm
              this.getSpad(null)
            })
            .catch(() => {
              // on cancel
            })
        } else
        {
          // 要签名
          this.showSign = true
        }
      } else
      {
        Toast.fail('当前未查询到记录！')
      }
    },
    // 签名弹窗提交回调
    getSpad (url) {
      // if (
      //   this.userInfo.username == '7002' ||
      //   this.userInfo.username == '000012'
      // ) {
      //   ybePostFileStr({
      //     autoid: this.formValue.autoid,
      //     username: this.userInfo.username,
      //     filebase64str: url
      //   }).then(res => {
      //     console.log(res)
      //     if (res.iserror == 0) {
      //       Toast('测试成功')
      //       this.showSign = false
      //       this.getAllPageDatas(this.param)
      //     }
      //   })
      // } else {
      //signMonthKqSgl
      signMonthKqSglRawJson({
        autoid: this.formValue.autoid,
        username: this.userInfo.username,
        filebase64str: url
      }).then(res => {
        console.log(res)
        if (res.iserror == 0)
        {
          Toast(this.$t('salaryDetails.sdTxt4'))
          this.showSign = false
          this.getAllPageDatas(this.param)
        }
      })
      // }
    },
    // 同意
    agree () {
      if (this.PrivacyTimeOut > 0)
      {
        return
      } else
      {
        agreeKqSignProtocol({
          empcode: this.empcode
        }).then(res => {
          if (res.data[0].info)
          {
            Notify({ type: 'danger', message: res.data[0].info })
          } else
          {
            this.showAgreePop = false
          }
        })
      }
    },
    // 不同意
    notAgree () {
      this.onClickLeft()
    },
    //考勤签收-隐私弹窗同意倒计时
    getTimeCount () {
      const TIME_COUNT = this.kq_agreement_timeout
      if (!this.PrivacyTimer)
      {
        this.PrivacyTimeOut = TIME_COUNT
        this.PrivacyTimer = setInterval(() => {
          if (this.PrivacyTimeOut > 0 && this.PrivacyTimeOut <= TIME_COUNT)
          {
            this.PrivacyTimeOut--
          } else
          {
            clearInterval(this.PrivacyTimer)
            this.PrivacyTimer = null
          }
        }, 1000)
      }
    },
    // 获取考勤签收相关参数
    getKqSignParamData () {
      getKqSignParam({ empcode: this.empcode }).then(res => {
        this.isagree = res.data[0].isagree
        this.kq_agreement_timeout = res.data[0].kq_agreement_timeout
        this.nosign = res.data[0].nosign
        this.use_kq_agreement = res.data[0].use_kq_agreement
        this.use_kq_feedback = res.data[0].use_kq_feedback
        this.use_kq_sign = res.data[0].use_kq_sign
        this.kq_agreement_url = res.data[0].kq_agreement_url
        if (this.use_kq_agreement == true && this.isagree == false)
        {
          this.showAgreePop = true
          this.getTimeCount()
        }
      })
    },
    // 回退
    onClickLeft () {
      // this.$router.back()
      const fromPage = this.$route.query.fromPage
      if (fromPage == 'home')
      {
        this.$router.push('/home').catch(err => {
          console.log(err)
        })
      } else
      {
        this.$router.push('/query').catch(err => {
          console.log(err)
        })
      }
    },
    // 日历关闭回调
    onConfirmCalendar (date) {
      const [start, end] = date
      this.showCalendar = false
      this.param =
        this.formatDate(start, 'yyyyMMdd') +
        '-' +
        this.formatDate(end, 'yyyyMMdd')
      // 查询页面
      this.getAllPageDatas(this.param)
    },
    // 根据某一天获取所在周的日期
    getMonDayAndSunDay (date) {
      let blank = [
        { week: '周一', date: '', fulldate: null },
        { week: '周二', date: '', fulldate: null },
        { week: '周三', date: '', fulldate: null },
        { week: '周四', date: '', fulldate: null },
        { week: '周五', date: '', fulldate: null },
        { week: '周六', date: '', fulldate: null },
        { week: '周日', date: '', fulldate: null }
      ]
      blank.forEach((el, idx) => {
        let fulldate = null
        if (new Date(date).getDay() > 0)
        {
          // 大于0表示不是星期日
          fulldate = new Date(
            new Date(date).getTime() -
            3600 * 1000 * 24 * (new Date(date).getDay() - (idx + 1))
          )
        } else
        {
          // 这是星期日
          fulldate = new Date(
            new Date(date).getTime() -
            3600 * 1000 * 24 * (new Date(date).getDay() - (idx - 6))
          )
        }
        el.fulldate = this.formatDate(fulldate, 'yyyy-MM-dd')
        let da = fulldate.getDate()
        if (da < 10)
        {
          da = '0' + da
        }
        el.date = da
      })
      this.weeks = blank
      // console.log(this.weeks, 'weeksweeks');
    },
    // 不够就补零
    add0 (m) {
      if (m > 0)
      {
        return m < 10 ? '0' + m : m
      } else
      {
        return ''
      }
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else if (rule == 'yyyyMMdd')
        {
          return y + '' + this.add0(m) + '' + this.add0(d)
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else
      {
        return ''
      }
    },
    // 选择某一天
    getOneDay (it) {
      if (this.checkdate == it.date && this.checkFullDate == it.fulldate)
      {
        this.checkdate = ''
        this.checkFullDate = ''
      } else
      {
        this.checkdate = it.date
        this.checkFullDate = it.fulldate
      }
      this.param = this.formatDate(this.checkFullDate, 'yyyyMMdd')
      // 查询页面
      this.getAllPageDatas(this.param)
    },
    // 左右切换周
    arrowDates (val) {
      if (val == 'left')
      {
        // 根据当前weeks里的第一天获取上周日期
        let preDate = new Date(
          new Date(this.weeks[0].fulldate).getTime() - 24 * 60 * 60 * 1000
        ) //前一天
        this.getMonDayAndSunDay(this.formatDate(preDate, 'yyyy-MM-dd'))
      } else if (val == 'right')
      {
        // 根据当前weeks里的最后一天获取下周日期
        let nextDate = new Date(
          new Date(this.weeks[6].fulldate).getTime() + 24 * 60 * 60 * 1000
        ) //后一天
        this.getMonDayAndSunDay(this.formatDate(nextDate, 'yyyy-MM-dd'))
      }
      // 查询某一天数据
      let isToday = false
      let todayIdx = 0
      for (let i = 0; i < this.weeks.length; i++)
      {
        const it = this.weeks[i]
        if (it.fulldate == this.formatDate(new Date(), 'yyyy-MM-dd'))
        {
          isToday = true
          todayIdx = i
        }
      }
      // 如果是当前日期就选当前日期，否则选择一周的星期一
      if (isToday)
      {
        this.getOneDay(this.weeks[todayIdx])
      } else
      {
        this.getOneDay(this.weeks[0])
      }
    },
    // 获取页面所有信息
    getAllPageDatas (param) {
      // 查询记录
      getHrQueryRecord({
        moduleno: this.moduleno,
        empcode: this.empcode,
        param: param,
        langid: this.langid
      }).then(res => {
        this.times += 1
        this.valueData = res.data
        this.formValue = res.data.length > 0 ? res.data[0] : {}
        // 针对模块号661-考勤月报特殊处理,调用已查阅接口
        if (
          (this.moduleno == 661 || this.moduleno == '661') &&
          res.data.length > 0
        )
        {
          openKqMonthReport({
            autoid: res.data[0].autoid,
            empid: this.userInfo.empid
          }).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            }
          })
        }
        // 查询页面
        this.getHrQueryPageInfoData({
          moduleno: this.moduleno,
          empcode: this.empcode,
          langid: this.langid
        })
      })
    },
    // 点击选择某个月份
    getOneMonth (v) {
      if (this.checkedMonth == v)
      {
        this.checkedMonth = ''
        this.param = ''
        // 查询页面
        this.getAllPageDatas(this.param)
      } else
      {
        this.checkedMonth = v
        if (this.checkedYear)
        {
          this.param = this.checkedYear + '' + this.add0(this.checkedMonth)
          // 查询页面
          this.getAllPageDatas(this.param)
        }
      }
    },
    // 点击选择某个年份
    getOneYear (v) {
      if (this.checkedYear == v)
      {
        this.checkedYear = ''
        this.param = ''
        // 查询页面
        this.getAllPageDatas(this.param)
      } else
      {
        this.checkedYear = v
        if (this.showMonths)
        {
          if (this.checkedMonth)
          {
            this.param = this.checkedYear + '' + this.add0(this.checkedMonth)
            // 查询页面
            this.getAllPageDatas(this.param)
          }
        } else
        {
          this.param = this.checkedYear + ''
          // 查询页面
          this.getAllPageDatas(this.param)
        }
      }
    },
    // 箭头切换年份
    arrowsYear (v) {
      if (v == 'left')
      {
        this.years.unshift(this.years[0] - 1)
        this.years.pop()
      } else
      {
        this.years.push(this.years[2] + 1)
        this.years.shift()
      }
    },
    // 根据日期获取年份列表
    getYearsByDate (v) {
      if (v)
      {
        let date = new Date(v)
        let year = date.getFullYear()
        this.years = [year - 1, year, year + 1]
        //
        this.checkedYear = year
      }
    },
    // 获取查询页面相关配置信息
    getHrQueryPageInfoData (data) {
      getHrQueryPageInfo({
        moduleno: data.moduleno,
        empcode: data.empcode,
        langid: data.langid
      }).then(res => {
        this.fieldlist = res.fieldlist
        this.pageinfo = res.pageinfo[0]
        const form = [2, 3, 5, 8]
        if (this.pageinfo.apptemplateid != 0)
        {
          if (form.includes(this.pageinfo.apptemplateid))
          {
            // 用表单显示
            this.showForm = true
            let afterData = []
            let tempArr = []
            // 遍历分组
            for (let i = 0; i < this.fieldlist.length; i++)
            {
              // 解析没有分组的表单信息
              if (!this.fieldlist[i].appgroupname)
              {
                this.noGroupForm = this.fieldlist.filter(it => {
                  return !it.appgroupname
                })
              } else
              {
                if (tempArr.indexOf(this.fieldlist[i].appgroupname) === -1)
                {
                  let arr = []
                  if (this.fieldlist[i].gridwith)
                  {
                    arr.push(JSON.parse(JSON.stringify(this.fieldlist[i])))
                  }
                  afterData.push({
                    name: this.fieldlist[i].appgroupname,
                    origin: arr
                  })
                  tempArr.push(this.fieldlist[i].appgroupname)
                } else
                {
                  for (let j = 0; j < afterData.length; j++)
                  {
                    if (afterData[j].name == this.fieldlist[i].appgroupname)
                    {
                      if (this.fieldlist[i].gridwith)
                      {
                        afterData[j].origin.push(
                          JSON.parse(JSON.stringify(this.fieldlist[i]))
                        )
                      }
                      break
                    }
                  }
                }
              }
            }
            this.groupForm = afterData
          } else
          {
            // 用表格显示
            this.thList = JSON.parse(JSON.stringify(this.fieldlist))
            let obj = {
              fieldname: 'serial',
              chnname: '序号',
              gridwith: 20,
              footerkind: 'skNone',
              appgroupname: ''
            }
            this.thList.unshift(obj)
            // console.log(this.thList, 'this.thList');
            this.tableData = JSON.parse(JSON.stringify(this.valueData))
            this.tableData.forEach((it, idx) => {
              it.serial = idx
            })
            console.log(this.tableData, 'this.tableData');
            // 如果表格启用汇总
            if (this.pageinfo.openfooter)
            {
              this.totalData = []
              this.totalData.push('汇总')
              this.thList.forEach(it => {
                if (it.fieldname != 'serial')
                {
                  if (it.gridwith && it.footerkind != 'skNone')
                  {
                    let result = ''
                    const values = this.tableData.map(item =>
                      parseFloat(item[it.fieldname])
                    )
                    if (!values.every(value => isNaN(value)))
                    {
                      switch (it.footerkind)
                      {
                        case 'skAverage':
                          result = this.computedAverage(values)
                          break
                        case 'skCount':
                          result = this.computedCount(values)
                          break
                        case 'skMax':
                          result = this.computedMax(values)
                          break
                        case 'skMin':
                          result = this.computedMin(values)
                          break
                        case 'skSum':
                          let tol = this.computedSum(values)
                          result = Math.round(tol * 100) / 100
                          break
                      }
                      result = String(result)
                      const dot = result.indexOf('.')
                      if (dot > -1)
                      {
                        result = result.slice(0, dot + 3)
                      }
                    }
                    this.totalData.push(result)
                  } else
                  {
                    if (it.gridwith && it.gridwith != 0)
                    {
                      this.totalData.push('')
                    }
                  }
                }
              })
              // console.log(this.totalData, 'this.totalData');
            }
          }
        }
      })
    },
    // 平均值
    computedAverage (arr) {
      return Math.floor((sum(arr) * 1000) / arr.length) / 1000
    },
    // 整数
    computedCount (arr) {
      return arr.length
    },
    // 最大值
    computedMax (arr) {
      return Math.max(...arr)
    },
    // 最小值
    computedMin (arr) {
      return Math.min(...arr)
    },
    // 求和
    computedSum (arr) {
      return arr.reduce((prev, curr) => {
        const value = Number(curr)
        if (!isNaN(value))
        {
          return prev + curr
        } else
        {
          return prev
        }
      }, 0)
    }
  }
}
</script>

<style scoped lang="less">
// 反馈意见弹窗
.FeedbackPop {
  height: 80%;
  width: 90%;
  .title {
    // color: #fff;
    height: 90px;
    padding: 20px 0;
    text-align: center;
    font-size: 36px;
    font-family: Source Han Sans CN;
  }
  .reason {
    height: calc(100% - 200px);
    padding: 10px 20px;
    .van-field {
      height: 100%;
      border: 1px solid #ebedf0;
      /deep/.van-field__value {
        .van-field__body {
          height: 100%;
          .van-field__control {
            height: 100% !important;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 32px;
    display: flex;
    justify-content: center;
    div {
      height: 80px;
      line-height: 80px;
      width: 80%;
      text-align: center;
      background: #2b8df0;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
    }
  }
}
//  协议弹窗
.salaryAgreePop {
  height: 80%;
  width: 90%;
  .title {
    // color: #fff;
    height: 90px;
    padding: 20px 0;
    text-align: center;
    font-size: 36px;
    font-family: Source Han Sans CN;
  }
  .content {
    height: calc(100% - 200px);
  }
  .footer {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 32px;
    display: flex;
    border-top: 1px solid #ebedf0;
    .arg1 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #247dff;
      line-height: 100px;
      border-right: 1px solid #ebedf0;
    }
    .arg2 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ee0a24;
      line-height: 100px;
    }
  }
}
.queryDetails {
  height: 100%;
  overflow: hidden;
  // background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-nav-bar__text {
        color: #ffffff;
        font-size: 36px;
      }
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-tag {
        margin-right: 24px;
        font-size: 24px;
        .van-icon {
          color: #ffffff;
        }
      }
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    background: #f6f6f6;
    // overflow: auto;
    height: calc(100% - 92px);
    .date_content {
      .year_box {
        display: flex;
        align-items: center;
        padding: 20px 0;
        background: #ffffff;
        .icon_box {
          display: flex;
          align-items: center;
          .van-icon {
            font-size: 40px;
            font-weight: bold;
          }
        }
        .year_btn {
          width: calc((100% - 40px) / 3);
          color: #2a2a2a;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          background-color: #eff6ff;
          border-radius: 10px;
          .year {
            padding: 10px 0;
          }
        }
        .cao0 {
          margin: 0 20px 0 10px;
        }
        .cao1 {
          margin-right: 20px;
        }
        .cao2 {
          margin-right: 10px;
        }
        .checkd_year {
          color: #ffffff;
          background: #5398ff;
        }
      }
      .month_box {
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        padding-bottom: 10px;
        .month_btn {
          width: calc(100% / 6);
          color: #aeaeae;
          font-size: 28px;
          text-align: center;
          .month {
            padding: 10px 0;
            margin: 10px;
          }
          .checkd_Month {
            color: #5095ff;
            background: #ffffff;
            border-radius: 30px;
            border: 1px solid #5095ff;
          }
        }
      }
      .date_box {
        .head_date {
          display: flex;
          align-items: center;
          padding: 20px 0;
          background: #fff;
          .icon_box {
            display: flex;
            align-items: center;
            .van-icon {
              font-size: 40px;
              font-weight: bold;
            }
          }
          .date_box {
            width: calc((100% - 40px) / 7);
            font-size: 28px;
            text-align: center;
            .week {
              margin-bottom: 10px;
            }
            .date {
              display: flex;
              justify-content: center;
              align-items: center;
              .text {
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 50%;
              }
              .bgtext {
                color: #fff;
                background: #2b8df0;
              }
            }
          }
        }
      }
    }
    .table_content {
      .auto_table {
        height: 100%;
        overflow: auto;
        .table {
          width: 100%;
          border-spacing: 0px;
          tr {
            td:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }
            th,
            td {
              padding: 6px 10px;
              text-align: center;
              border-bottom: 2px solid #dcdfe6;
              border-right: 2px solid #dcdfe6;
              .comm_box {
                .boolean_box {
                  .van-checkbox {
                    justify-content: center;
                    /deep/.van-checkbox__icon--checked {
                      .van-icon {
                        color: #fff !important;
                        background-color: #1989fa !important;
                        border-color: #1989fa !important;
                      }
                    }
                    /deep/.van-checkbox__icon--disabled .van-icon {
                      background-color: #fff;
                    }
                  }
                }
              }
            }
          }
          .top {
            position: sticky;
            top: 0;
            font-size: 28px;
            background: #e9f3fd;
            height: 64px;
            th:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }
          }
          .cont {
            height: 64px;
            font-size: 24px;
          }
          .cont:nth-child(2n-1) {
            background: #f5f5f5;
          }
        }
      }
    }
    .form_content {
      position: relative;
      .userInfoRight {
        position: absolute;
        top: 160px;
        right: 0.42667rem;
        display: flex;
        align-items: center;
        z-index: 9999;
        .svg_sigin {
          width: 130px;
          height: 130px;
          margin-right: 10px;
          .svg-icon {
            width: 100%;
            height: 100%;
          }
        }
      }
      /deep/.van-collapse {
        height: 100%;
        overflow-y: auto;
        .van-collapse-item {
          margin: 20px;
          .van-collapse-item__title {
            border-radius: 20px 20px 0 0;
            .van-cell__title {
              font-size: 32px;
              font-weight: bold;
              font-family: Source Han Sans CN;
              color: #000;
              .title_bg {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                img {
                  height: 100%;
                }
              }
              .xiba_title {
                display: flex;
                align-items: center;
                position: relative;
                .van-icon {
                  margin-right: 16px;
                }
              }
            }
          }
          .van-collapse-item__wrapper {
            border-radius: 0 0 20px 20px;
            .van-collapse-item__content {
              .peField {
                .van-field {
                  .van-field__label {
                    color: #aeaeae;
                    width: 8em;
                  }
                  .van-field__value {
                    color: #2a2a2a;
                  }
                }
                .not_canedi {
                  .van-field__value {
                    .van-field__body {
                      .van-field__control {
                        color: #2a2a2a;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .van-hairline--top-bottom::after {
        border: unset;
      }
      .noGroup_box {
        height: 100%;
        overflow-y: auto;
        .noGroup_form {
          padding: 0 50px;
          background-color: #fff;
          .peField {
            .van-field {
              .van-field__label {
                color: #aeaeae;
                width: 8em;
              }
              .van-field__value {
                color: #2a2a2a;
              }
            }
          }
        }
      }
    }
  }

  .has_feedback {
    height: 132px;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .has_feedback_btn1,
    .has_feedback_btn2 {
      height: 90px;
      text-align: center;
      line-height: 90px;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
    }
    .has_feedback_btn1 {
      width: 40%;
      background: rgb(255, 174, 201);
    }
    .has_feedback_btn2 {
      width: 40%;
      background: #2b8df0;
    }
  }
}
</style>
